<template>
	<div>
		
		<div 
			v-if="messagesFiltered && messagesFiltered.length"
			class="space-y-4 mb-6">
			
			<div
				v-for="message in messagesFiltered"
				:key="'messageId_'+message.messageId"
				class="rounded-lg bg-yellow-200 p-4">
				
				<div class="flex mb-2 ">
					<div class="opacity-50 flex-grow space-x-4">
						<span>{{ message.createdAt | moment('lll') }}</span>
						<span class="text-black" v-if="message.author">{{ message.author?.given_name && message.author?.family_name ? message.author?.given_name +' '+ message.author?.family_name : 'Aministrator' }}</span>
					</div>
					<div @click="onMarkMessageAsRead(message)">
						Skjul
					</div>
				</div>
				
				<div v-html="message.text" />
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
    props: {
        departments: {
            type: Array,
            required: true,
        },
		username: {
            type: String,
            required: true,
		},
    },
	
	computed: {
		messagesFiltered(){
			return this.departmentMessages.filter( message => {
				return !localStorage['message_'+message.messageId+':'+this.username];
			});
		},
	},
	
	data(){
		return {
			loading: null,
			departmentMessages: [],
		}
	},
	
	methods: {
		onMarkMessageAsRead(message){
			localStorage['message_'+message.messageId+':'+this.username] = new Date().getTime();
			this.departmentMessages = this.departmentMessages.filter( m => m.messageId != message.messageId);
		}
	},
	
	async mounted(){
		this.departments.forEach( d => {
			if (d.messages && d.messages.length) {
				d.messages.forEach( message => {
					this.departmentMessages.push({
						...message,
						departmentId: d.departmentId,
					});
				});
			}
		});
	},
}
</script>
